
import { Component, Vue, Prop } from 'vue-property-decorator'

import {
    userList
} from "@/api/serviceProvider"

import {
    debounce
} from "lodash"
@Component
export default class GetUserByRemote extends Vue {
    userLoading = false;
    allUserList = [];
    
    getUserRemote = debounce(function (str) {
        this.userSelectLoading = true;
        userList({
                phone: str,
                pageNum: 1,
                pageSize: 500
            })
            .then((res) => {
                this.userSelectLoading = false;
                this.allUserList = res.data || [];
            })
            .catch((err) => {});
    })

    getUserList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getUserRemote(str);
    }
}

