
import {
    Watch, Mixins, Component, PropSync, Ref
} from 'vue-property-decorator';

import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from '@/components/ETable.vue';
import { ElTable } from 'element-ui/types/table';

import { groupShareDeviceList } from "@/api/shareGroup";

@Component({
    components: { ETable }
})
export default class SelectDevice extends Mixins(loading, tablePagination, Resize) {
    @PropSync("visible", { default: false }) dialogVisible !: boolean;
    @Ref("tableRef") readonly tableRef!: ElTable;

    queryForm = {
        storeName: "",
        deviceTypeName: "",
        deviceMessageCode: "",
    };

    columns = [
        { label: " ", prop: "", type: "selection", width: 55, fix: "left" },
        { label: "设备ID", prop: "id", minWidth: "120" },
        { label: "设备型号", prop: "deviceName", minWidth: "120" },
        { label: "盒子编码", prop: "deviceMessageCode", minWidth: "120" },
        { label: "关联门店", prop: "storeName", minWidth: "120" },
    ]

    dataSource = []

    selectList = [];

    getData() {
        this.showLoading();
        const params = {
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        groupShareDeviceList(params).then(res => {
            this.dataSource = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    @Watch('dialogVisible')
    searchHandle() {
        if (!this.dialogVisible) return;
        this.resetCurrent();
        this.getData();
    }

    dialogClose() {
        const elTableInstance = this.tableRef.$children?.[0] as ElTable;
        if (elTableInstance) {
            elTableInstance.clearSelection();
        }
        this.dialogVisible = false;
    }

    selectChange($event) {
        this.selectList = $event;
    }

    cancel() {
        this.dialogClose();
    }

    confirm() {
        this.$emit("syncDeviceList", this.selectList);
        this.dialogClose();
    }

}


