
import {
    Watch, Mixins, Component, PropSync
} from 'vue-property-decorator'

import { groupRelationDevice } from "@/api/shareGroup";

import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getGroupRelationDevice } from "@/api/shareGroup";

import ETable from '@/components/ETable.vue';
import SelectDevice from "./selectDevice.vue";

import { uniqBy } from "lodash";

@Component({
    components: { ETable, SelectDevice }
})
export default class GroupRelationDevice extends Mixins(loading, tablePagination, Resize) {
    @PropSync("visible", { default: false }) dialogVisible !: boolean;
    @PropSync("modelForm") formData: any;

    columns = [
        { label: "设备ID", prop: "id", minWidth: "120" },
        { label: "设备型号", prop: "deviceName", minWidth: "120" },
        { label: "盒子编码", prop: "deviceMessageCode", minWidth: "120" },
        { label: "关联门店", prop: "storeName", minWidth: "100"},
        { label: "操作", prop: "action", minWidth: "100" },
    ]

    dataSource = []
    itemTotal = 0;

    selectDeviceVisible = false;

    mounted() {
        
    }

    selectDevice() {
        this.selectDeviceVisible = true;
    }

    dialogClose() {
        this.dialogVisible = false;
        this.dataSource = [];
    }

    handleDel(row) {
        this.dataSource = this.dataSource.filter(item => item.id !== row.id);
    }

    cancel() {
        this.dialogClose();
    }

    confirm() {
        const params = {
            groupId: this.formData.id,
            shareGroupDevices: this.dataSource?.map((el) => ({ deviceId: el.id, groupId: this.formData.id })),
        }
        groupRelationDevice(params).then(() => {
            this.dialogClose();
            this.$emit("refreshList");
        });
    }

    syncDeviceList(list) {
        console.log(list)
        this.dataSource = uniqBy([...this.dataSource, ...list], "id");
        console.log(this.dataSource)
    }

    @Watch('formData.id')
    @Watch('dialogVisible')
    getGroupDevice(){
        if (!this.dialogVisible || !this.formData.id) return;
        getGroupRelationDevice(this.formData.id).then((res) => {
            this.dataSource = res.data.map((el) => ({
                ...el.deviceManage,
                storeName: el.storeManage?.storeName
            }));
        })
    }
}

