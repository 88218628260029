
import {
    Component,
    Vue,
    PropSync, Prop,
    Ref,
    Watch
} from "vue-property-decorator";

import { addGroupCombo, getGroupComboDeviceList } from "@/api/shareGroup";
import { threadId } from "worker_threads";

@Component({
    components: {},
})
export default class AddComboForm extends Vue {
    @Ref("form") formRef: VForm;
    @PropSync("formVisible") visible: boolean;
    @PropSync("modelForm") formData: any;
    @Prop() group: any;

    saveLoading = false;

    form = {
        name: "",
        num: undefined,
        deviceTypeNameList: [],
        status: false,
    }

    formRules = {
        name: [{
            required: true,
            message: "请输入套餐名称",
            trigger: "blur"
        }],
        num: [{
            required: true,
            message: "请输入套餐次数",
            trigger: "blur"
        }],
        deviceTypeNameList: [{
            required: true,
            type: "array",
            message: "请选择套餐适用设备型号",
            trigger: "blur"
        }],
    }

    deviceTypeListOptions = [];

    get dialogTitle() {
        return `${this.formData.id ? "编辑" : "新增"}套餐`
    }

    mounted() {
        this.getGroupComboDeviceList();
    }

    handleClose() {
        this.formRef?.resetFields();
        this.visible = false;
    }

    cancel() {
        this.handleClose();
    }

    confirm() {
        this.formRef?.validate().then(() => {
            const _deviceTypeNameList = this.form.deviceTypeNameList.map(el => {
                return this.deviceTypeListOptions?.find((dt) => dt.deviceId == el)
            });
            const params = {
                ...this.form,
                groupId: this.group.id,
                status: Number(this.form.status),
                deviceTypeNameList: _deviceTypeNameList
            }
            addGroupCombo(params).then(() => {
                this.$message.success("操作成功");
                this.$emit('syncComboList');
                this.handleClose();
            })
            
        }).catch((err) => {
            console.log(err)
        })
    }

    @Watch("visible")
    getGroupComboDeviceList() {
        if (!this.visible) return;
        getGroupComboDeviceList().then((res) => {
            console.log("res", res);
            this.deviceTypeListOptions = res.data;
        })
    }

    @Watch("modelForm")
    setForm(newForm) {
        this.form = {
            ...newForm,
            deviceTypeNameList: newForm.deviceTypeNameList?.map((el: any) => el.deviceId),
            // users: newForm.users.map((el: any) => el.id),
            status: Boolean(this.formData.status)
        }
    }
}
